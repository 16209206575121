import {
  setSandboxEnabledCheck,
  isPluginFrontendSandboxEnabled as isPluginFrontendSandboxEnabledInOss,
} from 'app/features/plugins/sandbox/sandbox_plugin_loader_registry';

async function shouldLoadPluginInFrontendSandbox(params: { pluginId: string; isAngular?: boolean }): Promise<boolean> {
  const isEnabledInOss = await isPluginFrontendSandboxEnabledInOss(params);
  // TODO: add logic to call the storage API and determine if a plugin should run
  // if the storage doesn't have information about the plugin then return the value from the OSS registry
  return isEnabledInOss;
}

export function initSandboxPluginLoaderRegistry() {
  setSandboxEnabledCheck(shouldLoadPluginInFrontendSandbox);
}
